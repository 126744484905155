import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { useViewport } from "../../../shared/hooks";

import TrustedBy from "./TrustedBy";
import { trustedBySection } from "./InvestorStyle";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { backendDrivenEvents } from "../../../slices/onboarding.slice";
import { backendEvents } from "../../../shared/constants/backend-events";
import { useEffect } from "react";

// @ts-ignore
const TrustedByWrapper = styled(Grid)(trustedBySection);
const TrustedByHeading = styled.p`
  color: #243242;
  text-align: center;
  font-family: Figtree;
  font-size: 42px;
  font-weight: 600;
  line-height: 48px; /* 114.286% */
  margin: 0;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const TrustedBySubHeading = styled.p`
  color: #3e4b5b;
  text-align: center;
  font-family: Figtree;
  font-size: 28px;
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
  }
`;
export const TrustedBySection = () => {
  const matchesXS = useViewport(600).below;

  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_TRUSTED_BY_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  return (
    <TrustedByWrapper container spacing={matchesXS ? 4 : 0} ref={ref}>
      <Grid item xs={12} style={{ marginTop: matchesXS ? "20px" : "48px" }}>
        <TrustedByHeading>Trusted By</TrustedByHeading>
        <TrustedBySubHeading>
          Over 200 corporates have subscribed to our group health plans
        </TrustedBySubHeading>
      </Grid>

      <Grid item xs={12} style={{ marginBottom: matchesXS ? "20px" : "16px" }}>
        <TrustedBy />
      </Grid>
    </TrustedByWrapper>
  );
};

export default TrustedBySection;
